@import "../../App.scss";

.blog {
  max-width: 1024px;
  width: calc(100% - 2rem);
  margin: 4rem auto;

  @include respond(mobile) {
    max-width: 1024px;
    width: 95%;
  }
  .blog-heading {
    margin-bottom: o auto;
    margin-bottom: 1rem;
    margin-left: 6rem;

    @include respond(mobile) {
      margin-left: 30px;
      font-size: 1rem !important ;
      white-space: nowrap;
    }

    @include respond(tablet) {
      margin-left: 30px;
      font-size: 1.3rem !important ;
      white-space: nowrap;
    }
  }

  .blog-container {
    width: 90%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    margin-bottom: 3rem;

    @include respond(mobile) {
      grid-template-columns: 1fr;
      margin-top: 2rem;
    }

    @include respond(tablet) {
      grid-template-columns: 1fr;
      margin-top: 2rem;
    }
  }

  .blog-card {
    padding: 2rem;
    transition: 0.5s ease-in-out;
    background: $light;
    border-radius: 8px;

    &:hover {
      transform: scale(0.985);
    }
  }

  .blog-title {
    font-weight: 500;
    font-size: 0.7rem;
    margin-bottom: 0.5rem;

    @include respond(mobile) {
      font-size: 0.8rem;
    }

    @include respond(tablet) {
      font-size: 0.8rem;
    }

    @include respond(laptops) {
      font-size: 0.8rem;
    }
  }

  .read {
    display: flex;
    align-items: center;
    gap: 1rem;
    color: gray;

    @include respond(mobile) {
      font-size: 0.6rem;
    }

    @include respond(mobile) {
      font-size: 0.6rem;
    }

    @include respond(mobile) {
      font-size: 0.6rem;
    }
  }
}
