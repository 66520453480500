@import "../../App.scss";

.about {
  max-width: 1024px;
  width: calc(100% - 4rem);
  margin: 4rem auto;

  @include respond(mobile) {
    width: 100%;
  }

  .about-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1.5rem;
    margin: -2rem auto;

    @include respond(mobile) {
      grid-template-columns: 1fr;
      margin: 1rem auto;
      width: 90%;
      grid-gap: 0.5rem;
    }

    @include respond(tablet) {
      margin: 1rem auto;

      grid-template-columns: 1fr;
      width: 90%;
      grid-gap: 1.5rem;
    }

    @include respond(laptops) {
      grid-template-columns: 1fr;
      width: 90%;
      grid-gap: 1.5rem;
    }

    img {
      border-radius: 1rem;
      max-width: 100%;
      margin: 0 auto;
      height: auto;

      @include respond(mobile) {
        width: 100%;
      }

      @include respond(tablet) {
        width: 100%;
      }
    }

    .about-content {
      h1 {
        font-size: 3.6rem;
        font-weight: 800;

        @include respond(mobile) {
          font-size: 1.8rem;
          font-weight: 800;
          margin-bottom: 1rem;
        }

        @include respond(tablet) {
          font-size: 3rem;
          font-weight: 800;
          margin-bottom: 1rem;
        }
      }

      .about-paragraphs {
        p {
          font-size: 0.8rem;
          margin-bottom: 1.5rem;
        }
      }
      a {
        color: $deep;
        text-decoration: underline;
      }
    }
  }
}
