@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Karla:wght@200;300;400;500;600;700;800&display=swap");

:root {
  --header-height: 3rem;
  /* ========= Fonts and typography ========*/
  --biggest-font-size: 2.25rem;
  --h1-font-size: 1.5rem;
  --h2-font-size: 1.25rem;
  --h3-font-size: 1rem;
  --normal-font-size: 0.938rem;
  --small-font-size: 0.813rem;
  --smaller-font-size: 0.75rem;
  /* ========= Font weight ========*/
  --font-medium: 500;
  --font-semi-bold: 600;
  /* ========= Margenes ========*/
  --mb-1: 0.5rem;
  --mb-2: 1rem;
  --mb-3: 1.5rem;
  --mb-4: 2rem;
  --mb-5: 2.5rem;
  --mb-6: 3rem;
  /* ========= z index ========*/
  --z-tooltip: 10;
  --z-fixed: 100;
}

@media screen and (min-width: 768px) {
  :root {
    --biggest-font-size: 4rem;
    --h1-font-size: 2.25rem;
    --h2-font-size: 1.5rem;
    --h3-font-size: 1.25rem;
    --normal-font-size: 1rem;
    --small-font-size: 0.875rem;
    --smaller-font-size: 0.813rem;
  }
}

@mixin respond($breakpoint) {
  @if ($breakpoint==full-screen) {
    @media (min-width: 1201px) and (max-width: 1669px) {
      @content;
    }
  }

  @if ($breakpoint==desktops) {
    @media (min-width: 1025px) and (max-width: 1200px) {
      @content;
    }
  }

  @if ($breakpoint==laptops) {
    @media (min-width: 769px) and (max-width: 1024px) {
      @content;
    }
  }

  @if ($breakpoint==tablet) {
    @media (min-width: 481px) and (max-width: 768px) {
      @content;
    }
  }

  @if ($breakpoint==mobile) {
    @media (max-width: 576px) {
      @content;
    }
  }
}

$deep: #9e113d;
$medium: #f5ad7a;
$light: #efe3d8;
$black: #1a202c;
$gray: #4a5568;

.bd-container {
  max-width: 960px;
  width: calc(100% - 2rem);
  margin-left: var(--mb-2);
  margin-right: var(--mb-2);
}

.bd-grid {
  display: grid;
  gap: 1.5rem;
}

body {
  margin: var(--header-height) 0 0 0;
  font-size: var(--normal-font-size);
  line-height: 1.6;
  scroll-behavior: smooth;
}

html,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

a {
  text-decoration: none;
}

.heading {
  color: $black;
  font-weight: 600;
  width: 100%;
  font-size: 1.5rem;
  position: relative;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding-bottom: 0.5rem;
  margin-bottom: 2.5rem;

  @include respond(mobile) {
    width: 90%;
    font-size: 1.1rem;
    font-weight: 500;
    padding-bottom: 0.3rem;
    margin-bottom: 0;
  }

  @include respond(tablet) {
    font-size: 1.1rem;
    font-weight: 500;
    padding-bottom: 0.3rem;
    margin-bottom: 0;
  }

  @include respond(laptops) {
    font-size: 1.1rem;
    font-weight: 500;
  }
}

.public-wrapper {
  display: flex;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}

.container-wrapper {
  width: 120rem;
  margin: 0 auto;
}
