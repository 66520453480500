@import "../../App.scss";

header {
  background: white;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  box-shadow: 0px 4px 30px 0px rgba(26, 24, 24, 0.1);

  .inner-header {
    max-width: 1024px;
    margin: 0 auto;
    z-index: 100;
    height: var(--header-height);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 0;

    @include respond(mobile) {
      height: 70px;
      padding: 0.6rem;
    }

    .logo a {
      font-weight: 700;
      text-decoration: none;
      color: #9e113d;
      font-size: 1rem;
      z-index: 1000;

      @include respond(mobile) {
        font-size: 0.7rem;
      }
    }

    .menu {
      display: flex;
      font-size: 1.3rem;
      justify-content: center;
      align-items: center;
      gap: 0.4rem;
      color: #9e113d;
      cursor: pointer;
      z-index: 100;

      @include respond(mobile) {
        font-size: 0.7rem;
      }

      button {
        border: none;
        background: none;
        outline: none;
        font-size: 0.8rem;
        color: #9e113d;
      }
    }
  }
}
