@import "../../App.scss";

.courses-heading {
  margin-bottom: o auto;
  margin-bottom: 1rem;

  @include respond(mobile) {
    margin-left: 20px;
    font-size: 1rem !important ;
    white-space: nowrap;
  }
  @include respond(tablet) {
    margin-left: 30px;
    font-size: 1.3rem !important ;
    white-space: nowrap;
  }
}

img {
  margin-top: -1.3rem;
  width: 100%;
  height: 22rem;
  object-fit: cover;
}
