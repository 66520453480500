@import "../../App.scss";

.home {
  margin: 2rem auto;
  max-width: 1024px;
  align-content: center;
  padding: 5rem 0;

  @include respond(mobile) {
    margin-top: 0.7rem;
    width: calc(100% - 1rem);
    padding: 1rem 0;
  }

  @include respond(tablet) {
    margin-top: 2rem;
    width: calc(100% - 3rem);
    padding: 1rem 0;
  }

  .hero-container {
    display: grid;
    gap: 3rem;
    grid-template-columns: 1fr 1fr;

    @include respond(mobile) {
      grid-template-columns: 1fr;
      gap: 1rem;
    }
    @include respond(tablet) {
      grid-template-columns: 1fr;
    }

    .hero-content {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      padding: 1.5rem 0;
      margin: 0 auto;

      @include respond(mobile) {
        width: 100%;
      }
      @include respond(tablet) {
        width: 100%;
      }

      .hero-content-inner {
        width: 100%;
        margin: 0 auto;
        color: #323232;

        h1 {
          margin-bottom: 40px;

          .hero-content-line {
            margin: 0;

            .intro {
              font-weight: 500;
              font-size: 1rem;
              // letter-spacing: 1px;

              @include respond(mobile) {
                font-weight: 400;
                font-size: 1rem;
              }

              @include respond(tablet) {
                font-weight: 400;
                font-size: 1rem;
              }

              span {
                position: relative;
                &:before {
                  position: absolute;
                  left: -200%;
                  top: -150%;
                  content: "";
                  width: 80px;
                  height: 80px;
                  border-radius: 50%;
                  background: #f3c4a0;
                  z-index: -1;

                  @include respond(mobile) {
                    left: -200%;
                    top: -210%;
                  }
                }
              }
            }

            .hero-name {
              font-size: 3rem;
              color: $deep;
              font-weight: 700;

              @include respond(mobile) {
                font-size: 2rem;
                font-weight: 800;
              }
              @include respond(tablet) {
                font-size: 2.5rem;
              }
            }

            .hero-title {
              font-size: 1rem;
              font-weight: 500;
              margin-top: 2rem;
              letter-spacing: 2px;
              line-height: 2rem;

              @include respond(mobile) {
                margin-top: -0.6rem;
                font-size: 0.8rem;
                letter-spacing: 1px;
                line-height: 1.6rem;
              }

              @include respond(tablet) {
                margin-top: 1rem;
                font-size: 01rem;
                letter-spacing: 1px;
                line-height: 25px;
              }
            }
          }
        }
      }

      .connect {
        margin-top: -4rem;
        display: flex;
        padding: 20px 0;
        gap: 3rem;

        font-size: 0.6rem;
        font-weight: 600;
        justify-content: flex-start;
        width: 70%;

        @include respond(mobile) {
          position: relative;
          gap: 1rem;
          margin-top: -3rem;
        }

        @include respond(tablet) {
          position: relative;
          gap: 1rem;
          margin-top: -2rem;
        }

        span {
          cursor: pointer;

          a {
            text-decoration: none;
            display: flex;
            justify-content: center;
            align-items: center;
            color: black;
            transition: 0.3s ease-in-out;

            .fa {
              font-size: 1.5rem;

              @include respond(mobile) {
                font-size: 1rem;
              }
            }

            &:hover {
              transform: scale(1.4);
            }
          }

          // &:hover {
          //   color: white;
          //   background: $black;
          //   padding: 8px 24px;
          //   border-radius: 4px;
          // }
        }
      }
    }

    .home-image {
      border-radius: 0.8rem;
      max-width: 100%;
      margin: 0 auto;
      height: auto;
    }
  }
}
