@import "../../App.scss";

.year-container {
  .name {
    color: $deep;
    margin-bottom: 0.1rem;
    font-size: 1rem;
    font-weight: 600;

    @include respond(mobile) {
      margin-bottom: 0rem;
      font-size: 0.9rem;
    }
  }

  .issue {
    font-size: 0.9rem;
    @include respond(mobile) {
      font-size: 0.8rem;
    }
  }

  .issue-by {
    font-size: 0.9rem;
    font-weight: 600;

    @include respond(mobile) {
      font-size: 0.8rem;
    }
  }

  .date {
    font-style: italic;
    @include respond(mobile) {
      font-size: 0.8rem;
    }
  }

  .credential-link {
    text-decoration: underline;
    color: $black;
    margin-left: 0.5rem;

    @include respond(mobile) {
      font-size: 0.8rem;
    }
  }
}
