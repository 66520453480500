@import "../../App.scss";

.project-container {
  max-width: 1024px;
  width: calc(100% - 4rem);
  margin: 4rem auto;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .heading {
    margin-bottom: 0rem;
  }

  @include respond(mobile) {
    max-width: 1024px;
    width: calc(100% - 2rem);
  }

  @include respond(tablet) {
    max-width: 1024px;
    width: calc(100% - 2rem);
  }

  .project-card {
    width: 100%;
    margin: 0 auto;
    padding: 30px 30px;
    display: flex;
    align-items: center;
    background-color: $light;
    border-radius: 8px;
    transition: 0.5s ease-in-out;

    @include respond(mobile) {
      padding: 40px 20px;

      width: 95%;
      flex-direction: column;
      align-items: center;
    }

    @include respond(tablet) {
      padding: 40px 20px;

      width: 85%;
      flex-direction: column;
      align-items: center;
    }

    &:hover {
      border: 1px solid $deep;
      transform: scale(1.025);
    }

    .project-content {
      width: 70%;

      @include respond(mobile) {
        width: 100%;
        margin: 0 auto;
      }
      @include respond(tablet) {
        width: 100%;
        margin: 0 auto;
      }

      .project-title {
        color: $black;
        font-weight: 600;
        margin-bottom: 1rem;

        @include respond(mobile) {
          font-size: 1.5rem;
        }
      }

      .project-info {
        font-size: 0.8rem;
        color: $gray;
        margin-bottom: 2rem;
        width: 90%;
        letter-spacing: 1px;
        font-weight: 100;

        @include respond(mobile) {
          font-size: 0.7rem;
          width: 100%;
          margin: 30px auto;
          margin-bottom: 1rem;
          margin-top: -0.5rem;
        }
      }

      .project-technology {
        display: flex;
        gap: 1rem;
        color: $black;
        font-weight: 500;
        margin-bottom: 2rem;
        font-size: 0.6rem;
        text-transform: uppercase;

        span {
          letter-spacing: 1px;
          // border: 1px solid $gray;
          // padding: 0.5rem;
          display: flex;
          justify-content: center;
          align-items: center;
          @include respond(mobile) {
            letter-spacing: 0;
            padding: 0.1rem;
          }
        }

        @include respond(mobile) {
          font-size: 0.5rem;
          justify-content: space-around;
          margin-bottom: 0;
        }
      }

      .links {
        display: flex;
        gap: 2rem;
        font-size: 1rem;

        a {
          color: $gray;
        }

        .project-icon {
          cursor: pointer;
        }
      }
    }

    .portfolio-img {
      width: 40%;
      height: 15rem;
      margin-top: 0.6rem;
      // object-fit: cover;

      @include respond(mobile) {
        width: 200px;
        height: 200px;
        margin-top: 1rem;
      }

      @include respond(tablet) {
        width: 200px;
        height: 100%;
        margin-top: 1rem;
      }
    }
  }
}
