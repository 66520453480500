@import "../../App.scss";

.hamburger-menu {
  display: none;
  z-index: 9;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  height: 100%;
  width: 100%;
  overflow: hidden;

  .menu-secondary-background-color {
    background-color: #9e113d;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: -100;
  }

  .menu-layer {
    background: #efe3d8;
    height: 100%;
    overflow: hidden;

    .menu-links {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      @include respond(mobile) {
        top: 200px;
      }

      nav {
        max-width: 1024px;

        margin: 8rem auto;
        width: 100%;
        display: flex;
        justify-content: flex-end;

        @include respond(mobile) {
          max-width: 800px;
          width: calc(100% - 3rem);
        }

        @include respond(tablet) {
          max-width: 800px;
          width: calc(100% - 3rem);
        }

        ul {
          padding: 0;
          margin: 0;

          li {
            display: flex;
            list-style: none;
            font-size: 1rem;
            font-weight: 700;
            cursor: pointer;
            height: 40px;
            overflow: hidden;
            width: 300px;
            justify-content: flex-end;

            @include respond(mobile) {
              font-size: 1rem;
              height: 30px;
              width: 300px;
              font-weight: 600;
            }

            @include respond(tablet) {
              font-size: 1rem;
              width: 300px;
              height: 50px;
            }

            a {
              position: absolute;
              color: black;
              text-decoration: none;

              &:hover {
                color: #9e113d;
              }
            }
          }
        }
      }
    }
  }

  .locations {
    position: absolute;
    display: flex;
    bottom: -50px;
    justify-content: flex-start;
    padding: 40px 0;
    border-top: 1px solid rgb(143, 138, 138);
    font-size: 0.6rem;
    font-weight: 600;
    right: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 824px;
    width: calc(100% - 2rem);
    margin: 0 auto;

    span {
      cursor: pointer;
      margin: 0 1rem;

      a {
        text-decoration: none;
        display: flex;
        justify-content: center;
        align-items: center;
        color: black;
        transition: 0.3s ease-in-out;

        .fa {
          font-size: 1rem;

          @include respond(mobile) {
            font-size: 0.9rem;
          }
        }

        &:hover {
          transform: scale(1.4);
        }
      }

      // &:hover {
      //   color: white;
      //   background: $black;
      //   padding: 8px 24px;
      //   border-radius: 4px;
      // }
    }
  }
}
