@import "../../App.scss";

.features-header.talks {
  margin-bottom: 0 !important;
}

.talk {
  padding: 0.6rem 0 0.6rem;
}

img {
  width: 100%;
  margin-bottom: 1rem;
}
