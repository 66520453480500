@import "../../App.scss";
.footer {
  display: flex;
  justify-content: center;
  padding-bottom: 1rem;
  font-size: 0.8rem;

  @include respond(mobile) {
    font-size: 0.5rem;
  }
}
