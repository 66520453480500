@import "../../App.scss";

:root {
  --blue: #006eff;
}

.techie {
  max-width: 1024px;
  margin: 0 auto;

  @include respond(mobile) {
    width: 100%;
  }

  @include respond(tablet) {
    width: 100%;
  }

  .intro-section {
    margin-top: 4rem !important;
    max-width: 100%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 2rem 0 0;
    gap: 2rem;
    align-content: center;

    @include respond(mobile) {
      grid-template-columns: 1fr;
      max-width: 100%;
      box-shadow: none;
    }

    @include respond(tablet) {
      grid-template-columns: 1fr;

      flex-direction: column;
      width: 100%;
      box-shadow: none;
    }

    @include respond(laptops) {
      width: 90%;
      box-shadow: none;
    }

    @include respond(desktops) {
      width: 100%;
      box-shadow: none;
    }

    @include respond(full-screen) {
      width: 100%;
    }

    img {
      width: 100%;
      height: auto;

      @include respond(mobile) {
        max-width: 100%;
      }

      @include respond(tablet) {
        max-width: 100%;
      }
    }

    .book-intro-content {
      display: flex;
      flex-direction: column;
      // align-items: center;
      // max-width: 70%;
      // width: 70%;
      justify-content: center;
      margin-top: -6rem;
      margin-left: -1.5rem;

      @include respond(mobile) {
        margin: 0 auto;
      }

      @include respond(tablet) {
        margin: 0 auto;
      }

      .book-intro-title {
        font-size: 2rem;
        width: 100%;
        font-weight: 900;
        text-transform: uppercase;
        line-height: 2.5rem;
        margin-bottom: 2rem;

        @include respond(mobile) {
          font-size: 2rem;
          width: 100%;
          margin: 0 auto;
          margin-bottom: 2rem;
          text-align: center;
          line-height: 2.5rem;
        }

        @include respond(tablet) {
          font-size: 2.5rem;
          width: 100%;
          margin: 0 auto;
          margin-bottom: 2rem;
          line-height: 3.2 rem;
          text-align: center;
        }

        @include respond(laptops) {
          width: 100%;
          font-size: 2.5rem;
          line-height: 2.5rem;
        }

        @include respond(desktops) {
          width: 100%;
          font-size: 2rem;
          line-height: 2.8rem;
        }

        @include respond(full-screen) {
          width: 70%;
        }
      }

      .book-author {
        font-size: 1.2rem;
        color: $deep;

        @include respond(mobile) {
          font-size: 1rem;
          text-align: center;
        }

        @include respond(tablet) {
          font-size: 1rem;
          text-align: center;
        }

        @include respond(laptops) {
          font-size: 0.9rem;
          text-align: center;
        }

        @include respond(desktops) {
          font-size: 0.9rem;
          text-align: center;
        }
      }
    }
  }

  .available {
    text-align: center;

    h4 {
      font-size: 1.5rem;
      font-weight: 700;
      margin-bottom: 3rem;
    }

    div.brand {
      text-align: center;
      display: flex;
      justify-content: center;
      // align-items: center;
      // width: 20%;
      margin: 0 auto;
      margin-bottom: 0rem;
      gap: 4rem;

      img.selar {
        width: 56px;
        height: 30px;
      }

      img:last-child {
        width: 100px;
        height: 30px;
      }
    }

    .btn {
      margin-top: 1rem;
      margin-bottom: 2rem;
    }

    a {
      background: var(--blue);
      color: white;
      border: none;
      padding: 1rem;
      font-size: 1.1rem;
      border-radius: 8px;
      transition: 0.3s all ease-in-out;

      &:first-child {
        margin-right: 1rem;
      }

      &:hover {
        color: var(--blue);
        background: white;
        border: 2px solid var(--blue);
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      }
    }
  }

  .paragraph {
    width: 100%;
    font-size: 1.2rem;
    margin: 0 auto;
    margin-bottom: 5rem;
    text-align: center;

    @include respond(mobile) {
      width: 85%;
      text-align: center;
      font-size: 1rem;
    }

    p {
      margin-bottom: 1rem;
      line-height: 1.8rem;
      font-family: "Karla", sans-serif;

      span {
        font-style: italic;
      }
    }
  }

  .head {
    margin: 0 auto;
    width: 70%;
    margin-bottom: 3rem;

    @include respond(mobile) {
      width: 85%;
      text-align: center;
      font-size: 0.9rem;
    }

    h1 {
      color: var(--blue);
      font-size: 1.3rem;
      font-weight: 700;
      text-align: center;
    }

    p {
      margin-top: 4rem;
      font-size: 1.2rem;
      font-family: "Karla", sans-serif;
    }

    p:last-child {
      font-style: italic;
      text-align: center;

      @include respond(mobile) {
        font-size: 1rem;
      }

      a {
        color: var(--blue);
        text-decoration: underline;
      }
    }
  }

  .convinced {
    text-align: center;
    margin-bottom: 3rem;

    h2 {
      margin-top: 2rem;

      @include respond(mobile) {
        font-size: 1rem;
      }
    }

    a {
      background: black;
      color: white;
      border: none;
      padding: 1rem;
      font-size: 1.1rem;
      border-radius: 8px;
      transition: 0.3s all ease-in-out;

      &:hover {
        color: black;
        background: white;
        border: 2px solid black;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      }
    }
  }

  .inside {
    border-top: 1px solid rgb(151, 145, 145);
    margin-top: 7rem;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 5rem;

    @include respond(mobile) {
      width: 95%;
    }

    @include respond(laptops) {
      width: 100%;
    }

    h2 {
      text-align: center;
      margin-bottom: 2rem;
      margin-top: 3rem;
      color: var(--blue);
      text-transform: uppercase;
    }

    .inside-wrapper {
      display: grid;
      margin: 0 auto;

      @media (min-width: 489px) {
        width: 90%;
        grid-template-columns: repeat(1, 1fr);
      }

      @media (min-width: 760px) {
        min-width: 90%;
        margin: 0 auto;
        grid-template-columns: repeat(2, 1fr);
      }

      @media (min-width: 1200px) {
        min-width: 100%;
        margin: 0 auto;
        grid-template-columns: repeat(3, 1fr);
      }

      .inside-card {
        // margin: 0 auto;
        padding: 2rem;
        border-radius: 8px;
        display: flex;
        gap: 1rem;
        margin-bottom: 2rem;
        box-shadow: 0px -19px 100px 6px rgba(0, 0, 0, 0.1);

        .number {
          height: 60px;
          width: 60px;
          font-weight: 600;
          color: white;
          border-radius: 50%;
          font-size: 1.5rem;
          display: flex;
          justify-content: center;
          align-items: center;
          background: var(--blue);
        }

        .inside-content {
          width: 70%;

          h1 {
            font-size: 1.2rem;
            font-weight: 700;
            font-family: "Karla", sans-serif;
          }

          p {
            color: gray;
            font-size: 0.9rem;
            font-family: "Karla", sans-serif;
          }
        }
      }

      .inside-wrapper {
        grid-column-end: 5;
      }
    }
  }

  .contributors {
    width: 100%;
    border-top: 1px solid rgb(151, 145, 145);
    padding-top: 2rem;
    margin: 0 auto;
    margin-bottom: 4rem;

    @include respond(mobile) {
      width: 90%;
    }

    @include respond(tablet) {
      width: 95%;
    }

    h1 {
      text-align: center;
      color: var(--blue);
      font-weight: 800;
    }

    p {
      margin-bottom: 4rem;
      text-align: center;
    }

    .contributor-wrapper {
      width: 100%;
      // max-width: 90%;
      // min-width: 90%;
      margin: 0 auto;
      display: grid;
      grid-gap: 1rem;

      @media (min-width: 489px) {
        width: 100%;
        grid-template-columns: repeat(2, 1fr);
      }

      @media (min-width: 960px) {
        min-width: 100%;
        margin: 0 auto;
        grid-template-columns: repeat(3, 1fr);
      }

      @media (min-width: 1200px) {
        min-width: 100%;
        margin: 0 auto;
        grid-template-columns: repeat(3, 1fr);
      }

      @media (min-width: 1550px) {
        min-width: 100%;
        margin: 0 auto;
        grid-template-columns: repeat(3, 1fr);
      }

      // grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

      // @include respond(mobile) {
      //   grid-template-columns: 1fr;
      // }

      // @include respond(tablet) {
      //   grid-template-columns: 1fr 1fr;
      // }

      // @include respond(laptops) {
      //   grid-template-columns: 1fr 1fr;
      // }

      .contributor-card {
        box-shadow: 0px -19px 100px 6px rgba(0, 0, 0, 0.1);
        margin-bottom: 1rem;
        padding: 0.2rem;
        border-radius: 20px;

        @include respond(mobile) {
          padding: 0.1rem;
        }
        img {
          display: flex;
          margin: 0 auto;
          width: 100%;
          height: 24rem;
          border-radius: 1rem;
        }

        .co-about {
          text-align: center;

          p:first-child {
            margin-top: 1.5rem;
            font-size: 1rem;
            font-weight: 800;
            margin-bottom: 0.5rem;
            letter-spacing: 1px;
            font-family: "Karla", sans-serif;
          }

          p.role {
            margin-bottom: 0.6rem;
            font-family: "Karla", sans-serif;
            font-weight: 500;
          }

          .connect {
            display: flex;
            justify-content: center;
            color: #006eff;
            font-size: 1.5rem;
            gap: 1rem;
          }
        }
      }
    }
  }

  .book-mockup {
    border-top: 1px solid rgb(151, 145, 145);
    padding-top: 3rem;
    width: 80%;
    margin: 0 auto;
    text-align: center;

    @include respond(mobile) {
      width: 90%;
    }

    @include respond(tablet) {
      width: 90%;
    }

    h1 {
      color: var(--blue);
      text-align: center;
      margin-bottom: 3rem;
      font-weight: 800;
    }

    .book-mockup-wrapper {
      width: 90%;
      margin: 0 auto;
      gap: 2rem;
      display: grid;
      grid-template-columns: 1fr 1fr;
      margin-bottom: 2.5rem;

      @include respond(mobile) {
        width: 100%;
        grid-template-columns: 1fr;
      }

      @include respond(tablet) {
        width: 80%;
        grid-template-columns: 1fr;
      }

      @include respond(laptops) {
        width: 80%;
        grid-template-columns: 1fr;
      }

      @include respond(desktops) {
        width: 80%;
        grid-template-columns: 1fr;
      }

      img {
        width: 100%;
        border-radius: 1rem;
      }
    }

    a {
      background: var(--blue);
      color: white;
      border: none;
      padding: 1rem;
      font-size: 1.1rem;
      border-radius: 8px;
      transition: 0.3s all ease-in-out;
      margin-right: 1rem;

      &:hover {
        color: var(--blue);
        background: white;
        border: 2px solid var(--blue);
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      }
    }
  }

  .author {
    border-top: 1px solid rgb(151, 145, 145);
    padding-top: 4rem;
    width: 95%;
    margin: 0 auto;
    margin-bottom: 5rem !important;
    margin-top: 10rem;

    h1 {
      text-align: center;
      color: var(--blue);
      font-weight: 800;
      margin-bottom: 3rem;
    }

    .wrapper {
      display: grid;
      grid-template-columns: 1fr 2fr;
      justify-content: center;
      gap: 2rem;
      align-items: center;

      @include respond(mobile) {
        width: 100%;
        grid-template-columns: 1fr;
      }

      @include respond(tablet) {
        width: 100%;
        grid-template-columns: 1fr;
      }

      @include respond(laptops) {
        width: 100%;
        grid-template-columns: 1fr;
      }

      @include respond(desktops) {
        width: 100%;
        grid-template-columns: 1fr;
      }

      img {
        max-width: 100%;
        height: 90%;
        margin-top: -2rem;
        border-radius: 1rem;
      }

      .content {
        p {
          font-family: "Karla", sans-serif;
          font-size: 1.12rem;

          @include respond(mobile) {
            width: 90%;
            margin: 0 auto;
            font-size: 0.9rem;
          }

          @include respond(tablet) {
            font-size: 0.9rem;
            width: 100%;
            margin: 0 auto;
          }
        }

        p.quote {
          font-style: italic;
          font-weight: 800;
          color: rgb(216, 203, 203);
        }
      }
    }
  }
  .pre-order {
    align-items: center;
  }
}
