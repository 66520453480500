@import "../../App.scss";

.feature {
  max-width: 1024px;
  width: calc(100% - 4rem);
  margin: 4rem auto;

  @include respond(mobile) {
    max-width: 1024px;
    width: 95%;
  }

  .features-heading {
    margin-bottom: o auto;
    margin-bottom: 1rem;

    @include respond(mobile) {
      margin-left: 20px;
    }
    @include respond(tablet) {
      margin-left: 30px;
    }
  }

  .features {
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
    grid-gap: 20px;
    padding-bottom: 3rem;

    @include respond(mobile) {
      width: 90%;
      grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
    }

    @include respond(tablet) {
      width: 90%;
      grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
    }

    @include respond(laptops) {
      width: 90%;
      grid-template-columns: repeat(auto-fill, minmax(40%, 1fr));
    }

    @include respond(desktops) {
      width: 90%;
      grid-template-columns: repeat(auto-fill, minmax(40%, 1fr));
    }

    .features-card {
      display: flex;
      flex-direction: column;
      background: white;
      border-radius: 4px;
      transition: 0.5s ease-in-out;
      box-shadow: 0px -19px 100px 6px rgba(0, 0, 0, 0.1);

      &:hover {
        transform: scale(1.025);
        // background: $deep;

        // .features-title {
        //   color: rgb(231, 205, 205) !important;
        // }
        // .features-subtitle {
        //   color: rgb(231, 205, 205) !important;
        // }

        // .features-date {
        //   color: whitesmoke !important;
        // }
      }

      .card-wrapper {
        padding: 20px;
        display: flex;
        flex-direction: column;
      }

      .features-header {
        margin-bottom: 2rem;
        .features-title {
          font-weight: 500;
          font-size: 0.9rem;
          color: $deep;
          margin-bottom: 0.1rem;

          @include respond(mobile) {
            font-size: 0.7rem;
          }

          @include respond(tablet) {
            font-size: 0.8rem;
          }
        }

        .features-subtitle {
          color: black;
          font-size: 0.6rem;
          margin-bottom: 0rem;

          @include respond(mobile) {
            font-size: 0.6rem;
          }

          @include respond(tablet) {
            font-size: 0.7rem;
          }
        }

        .features-date {
          color: black;
          font-size: 0.6rem;
          text-transform: uppercase;
          letter-spacing: 1px;

          @include respond(mobile) {
            font-size: 0.5rem;
          }

          @include respond(tablet) {
            font-size: 0.5rem;
          }
        }
      }

      img {
        margin-top: -1.3rem;
        max-width: 100%;
        height: 22rem;
        // object-fit: cover;
      }
    }
  }
}
