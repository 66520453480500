@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Karla:wght@200;300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Karla:wght@200;300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Karla:wght@200;300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Karla:wght@200;300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Karla:wght@200;300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Karla:wght@200;300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Karla:wght@200;300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Karla:wght@200;300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Karla:wght@200;300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Karla:wght@200;300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Karla:wght@200;300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Karla:wght@200;300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Karla:wght@200;300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Karla:wght@200;300;400;500;600;700;800&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --header-height: 3rem;
  /* ========= Fonts and typography ========*/
  --biggest-font-size: 2.25rem;
  --h1-font-size: 1.5rem;
  --h2-font-size: 1.25rem;
  --h3-font-size: 1rem;
  --normal-font-size: 0.938rem;
  --small-font-size: 0.813rem;
  --smaller-font-size: 0.75rem;
  /* ========= Font weight ========*/
  --font-medium: 500;
  --font-semi-bold: 600;
  /* ========= Margenes ========*/
  --mb-1: 0.5rem;
  --mb-2: 1rem;
  --mb-3: 1.5rem;
  --mb-4: 2rem;
  --mb-5: 2.5rem;
  --mb-6: 3rem;
  /* ========= z index ========*/
  --z-tooltip: 10;
  --z-fixed: 100;
}

@media screen and (min-width: 768px) {
  :root {
    --biggest-font-size: 4rem;
    --h1-font-size: 2.25rem;
    --h2-font-size: 1.5rem;
    --h3-font-size: 1.25rem;
    --normal-font-size: 1rem;
    --small-font-size: 0.875rem;
    --smaller-font-size: 0.813rem;
  }
}
.bd-container {
  max-width: 960px;
  width: calc(100% - 2rem);
  margin-left: 1rem;
  margin-left: var(--mb-2);
  margin-right: 1rem;
  margin-right: var(--mb-2);
}

.bd-grid {
  display: grid;
  grid-gap: 1.5rem;
  gap: 1.5rem;
}

body {
  margin: 3rem 0 0 0;
  margin: var(--header-height) 0 0 0;
  font-size: 0.938rem;
  font-size: var(--normal-font-size);
  line-height: 1.6;
  scroll-behavior: smooth;
}

html,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

a {
  text-decoration: none;
}

.heading {
  color: #1a202c;
  font-weight: 600;
  width: 100%;
  font-size: 1.5rem;
  position: relative;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding-bottom: 0.5rem;
  margin-bottom: 2.5rem;
}
@media (max-width: 576px) {
  .heading {
    width: 90%;
    font-size: 1.1rem;
    font-weight: 500;
    padding-bottom: 0.3rem;
    margin-bottom: 0;
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .heading {
    font-size: 1.1rem;
    font-weight: 500;
    padding-bottom: 0.3rem;
    margin-bottom: 0;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .heading {
    font-size: 1.1rem;
    font-weight: 500;
  }
}

.public-wrapper {
  display: flex;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}

.container-wrapper {
  width: 120rem;
  margin: 0 auto;
}
:root {
  --header-height: 3rem;
  /* ========= Fonts and typography ========*/
  --biggest-font-size: 2.25rem;
  --h1-font-size: 1.5rem;
  --h2-font-size: 1.25rem;
  --h3-font-size: 1rem;
  --normal-font-size: 0.938rem;
  --small-font-size: 0.813rem;
  --smaller-font-size: 0.75rem;
  /* ========= Font weight ========*/
  --font-medium: 500;
  --font-semi-bold: 600;
  /* ========= Margenes ========*/
  --mb-1: 0.5rem;
  --mb-2: 1rem;
  --mb-3: 1.5rem;
  --mb-4: 2rem;
  --mb-5: 2.5rem;
  --mb-6: 3rem;
  /* ========= z index ========*/
  --z-tooltip: 10;
  --z-fixed: 100;
}

@media screen and (min-width: 768px) {
  :root {
    --biggest-font-size: 4rem;
    --h1-font-size: 2.25rem;
    --h2-font-size: 1.5rem;
    --h3-font-size: 1.25rem;
    --normal-font-size: 1rem;
    --small-font-size: 0.875rem;
    --smaller-font-size: 0.813rem;
  }
}
.bd-container {
  max-width: 960px;
  width: calc(100% - 2rem);
  margin-left: 1rem;
  margin-left: var(--mb-2);
  margin-right: 1rem;
  margin-right: var(--mb-2);
}

.bd-grid {
  display: grid;
  grid-gap: 1.5rem;
  gap: 1.5rem;
}

body {
  margin: 3rem 0 0 0;
  margin: var(--header-height) 0 0 0;
  font-size: 0.938rem;
  font-size: var(--normal-font-size);
  line-height: 1.6;
  scroll-behavior: smooth;
}

html,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

a {
  text-decoration: none;
}

.heading {
  color: #1a202c;
  font-weight: 600;
  width: 100%;
  font-size: 1.5rem;
  position: relative;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding-bottom: 0.5rem;
  margin-bottom: 2.5rem;
}
@media (max-width: 576px) {
  .heading {
    width: 90%;
    font-size: 1.1rem;
    font-weight: 500;
    padding-bottom: 0.3rem;
    margin-bottom: 0;
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .heading {
    font-size: 1.1rem;
    font-weight: 500;
    padding-bottom: 0.3rem;
    margin-bottom: 0;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .heading {
    font-size: 1.1rem;
    font-weight: 500;
  }
}

.public-wrapper {
  display: flex;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}

.container-wrapper {
  width: 120rem;
  margin: 0 auto;
}

header {
  background: white;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  box-shadow: 0px 4px 30px 0px rgba(26, 24, 24, 0.1);
}
header .inner-header {
  max-width: 1024px;
  margin: 0 auto;
  z-index: 100;
  height: 3rem;
  height: var(--header-height);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 0;
}
@media (max-width: 576px) {
  header .inner-header {
    height: 70px;
    padding: 0.6rem;
  }
}
header .inner-header .logo a {
  font-weight: 700;
  text-decoration: none;
  color: #9e113d;
  font-size: 1rem;
  z-index: 1000;
}
@media (max-width: 576px) {
  header .inner-header .logo a {
    font-size: 0.7rem;
  }
}
header .inner-header .menu {
  display: flex;
  font-size: 1.3rem;
  justify-content: center;
  align-items: center;
  grid-gap: 0.4rem;
  gap: 0.4rem;
  color: #9e113d;
  cursor: pointer;
  z-index: 100;
}
@media (max-width: 576px) {
  header .inner-header .menu {
    font-size: 0.7rem;
  }
}
header .inner-header .menu button {
  border: none;
  background: none;
  outline: none;
  font-size: 0.8rem;
  color: #9e113d;
}
:root {
  --header-height: 3rem;
  /* ========= Fonts and typography ========*/
  --biggest-font-size: 2.25rem;
  --h1-font-size: 1.5rem;
  --h2-font-size: 1.25rem;
  --h3-font-size: 1rem;
  --normal-font-size: 0.938rem;
  --small-font-size: 0.813rem;
  --smaller-font-size: 0.75rem;
  /* ========= Font weight ========*/
  --font-medium: 500;
  --font-semi-bold: 600;
  /* ========= Margenes ========*/
  --mb-1: 0.5rem;
  --mb-2: 1rem;
  --mb-3: 1.5rem;
  --mb-4: 2rem;
  --mb-5: 2.5rem;
  --mb-6: 3rem;
  /* ========= z index ========*/
  --z-tooltip: 10;
  --z-fixed: 100;
}

@media screen and (min-width: 768px) {
  :root {
    --biggest-font-size: 4rem;
    --h1-font-size: 2.25rem;
    --h2-font-size: 1.5rem;
    --h3-font-size: 1.25rem;
    --normal-font-size: 1rem;
    --small-font-size: 0.875rem;
    --smaller-font-size: 0.813rem;
  }
}
.bd-container {
  max-width: 960px;
  width: calc(100% - 2rem);
  margin-left: 1rem;
  margin-left: var(--mb-2);
  margin-right: 1rem;
  margin-right: var(--mb-2);
}

.bd-grid {
  display: grid;
  grid-gap: 1.5rem;
  gap: 1.5rem;
}

body {
  margin: 3rem 0 0 0;
  margin: var(--header-height) 0 0 0;
  font-size: 0.938rem;
  font-size: var(--normal-font-size);
  line-height: 1.6;
  scroll-behavior: smooth;
}

html,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

a {
  text-decoration: none;
}

.heading {
  color: #1a202c;
  font-weight: 600;
  width: 100%;
  font-size: 1.5rem;
  position: relative;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding-bottom: 0.5rem;
  margin-bottom: 2.5rem;
}
@media (max-width: 576px) {
  .heading {
    width: 90%;
    font-size: 1.1rem;
    font-weight: 500;
    padding-bottom: 0.3rem;
    margin-bottom: 0;
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .heading {
    font-size: 1.1rem;
    font-weight: 500;
    padding-bottom: 0.3rem;
    margin-bottom: 0;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .heading {
    font-size: 1.1rem;
    font-weight: 500;
  }
}

.public-wrapper {
  display: flex;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}

.container-wrapper {
  width: 120rem;
  margin: 0 auto;
}

.hamburger-menu {
  display: none;
  z-index: 9;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.hamburger-menu .menu-secondary-background-color {
  background-color: #9e113d;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: -100;
}
.hamburger-menu .menu-layer {
  background: #efe3d8;
  height: 100%;
  overflow: hidden;
}
.hamburger-menu .menu-layer .menu-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
@media (max-width: 576px) {
  .hamburger-menu .menu-layer .menu-links {
    top: 200px;
  }
}
.hamburger-menu .menu-layer .menu-links nav {
  max-width: 1024px;
  margin: 8rem auto;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
@media (max-width: 576px) {
  .hamburger-menu .menu-layer .menu-links nav {
    max-width: 800px;
    width: calc(100% - 3rem);
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .hamburger-menu .menu-layer .menu-links nav {
    max-width: 800px;
    width: calc(100% - 3rem);
  }
}
.hamburger-menu .menu-layer .menu-links nav ul {
  padding: 0;
  margin: 0;
}
.hamburger-menu .menu-layer .menu-links nav ul li {
  display: flex;
  list-style: none;
  font-size: 1rem;
  font-weight: 700;
  cursor: pointer;
  height: 40px;
  overflow: hidden;
  width: 300px;
  justify-content: flex-end;
}
@media (max-width: 576px) {
  .hamburger-menu .menu-layer .menu-links nav ul li {
    font-size: 1rem;
    height: 30px;
    width: 300px;
    font-weight: 600;
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .hamburger-menu .menu-layer .menu-links nav ul li {
    font-size: 1rem;
    width: 300px;
    height: 50px;
  }
}
.hamburger-menu .menu-layer .menu-links nav ul li a {
  position: absolute;
  color: black;
  text-decoration: none;
}
.hamburger-menu .menu-layer .menu-links nav ul li a:hover {
  color: #9e113d;
}
.hamburger-menu .locations {
  position: absolute;
  display: flex;
  bottom: -50px;
  justify-content: flex-start;
  padding: 40px 0;
  border-top: 1px solid rgb(143, 138, 138);
  font-size: 0.6rem;
  font-weight: 600;
  right: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  max-width: 824px;
  width: calc(100% - 2rem);
  margin: 0 auto;
}
.hamburger-menu .locations span {
  cursor: pointer;
  margin: 0 1rem;
}
.hamburger-menu .locations span a {
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  transition: 0.3s ease-in-out;
}
.hamburger-menu .locations span a .fa {
  font-size: 1rem;
}
@media (max-width: 576px) {
  .hamburger-menu .locations span a .fa {
    font-size: 0.9rem;
  }
}
.hamburger-menu .locations span a:hover {
  -webkit-transform: scale(1.4);
          transform: scale(1.4);
}
:root {
  --header-height: 3rem;
  /* ========= Fonts and typography ========*/
  --biggest-font-size: 2.25rem;
  --h1-font-size: 1.5rem;
  --h2-font-size: 1.25rem;
  --h3-font-size: 1rem;
  --normal-font-size: 0.938rem;
  --small-font-size: 0.813rem;
  --smaller-font-size: 0.75rem;
  /* ========= Font weight ========*/
  --font-medium: 500;
  --font-semi-bold: 600;
  /* ========= Margenes ========*/
  --mb-1: 0.5rem;
  --mb-2: 1rem;
  --mb-3: 1.5rem;
  --mb-4: 2rem;
  --mb-5: 2.5rem;
  --mb-6: 3rem;
  /* ========= z index ========*/
  --z-tooltip: 10;
  --z-fixed: 100;
}

@media screen and (min-width: 768px) {
  :root {
    --biggest-font-size: 4rem;
    --h1-font-size: 2.25rem;
    --h2-font-size: 1.5rem;
    --h3-font-size: 1.25rem;
    --normal-font-size: 1rem;
    --small-font-size: 0.875rem;
    --smaller-font-size: 0.813rem;
  }
}
.bd-container {
  max-width: 960px;
  width: calc(100% - 2rem);
  margin-left: 1rem;
  margin-left: var(--mb-2);
  margin-right: 1rem;
  margin-right: var(--mb-2);
}

.bd-grid {
  display: grid;
  grid-gap: 1.5rem;
  gap: 1.5rem;
}

body {
  margin: 3rem 0 0 0;
  margin: var(--header-height) 0 0 0;
  font-size: 0.938rem;
  font-size: var(--normal-font-size);
  line-height: 1.6;
  scroll-behavior: smooth;
}

html,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

a {
  text-decoration: none;
}

.heading {
  color: #1a202c;
  font-weight: 600;
  width: 100%;
  font-size: 1.5rem;
  position: relative;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding-bottom: 0.5rem;
  margin-bottom: 2.5rem;
}
@media (max-width: 576px) {
  .heading {
    width: 90%;
    font-size: 1.1rem;
    font-weight: 500;
    padding-bottom: 0.3rem;
    margin-bottom: 0;
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .heading {
    font-size: 1.1rem;
    font-weight: 500;
    padding-bottom: 0.3rem;
    margin-bottom: 0;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .heading {
    font-size: 1.1rem;
    font-weight: 500;
  }
}

.public-wrapper {
  display: flex;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}

.container-wrapper {
  width: 120rem;
  margin: 0 auto;
}

.about {
  max-width: 1024px;
  width: calc(100% - 4rem);
  margin: 4rem auto;
}
@media (max-width: 576px) {
  .about {
    width: 100%;
  }
}
.about .about-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.5rem;
  margin: -2rem auto;
}
@media (max-width: 576px) {
  .about .about-wrapper {
    grid-template-columns: 1fr;
    margin: 1rem auto;
    width: 90%;
    grid-gap: 0.5rem;
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .about .about-wrapper {
    margin: 1rem auto;
    grid-template-columns: 1fr;
    width: 90%;
    grid-gap: 1.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .about .about-wrapper {
    grid-template-columns: 1fr;
    width: 90%;
    grid-gap: 1.5rem;
  }
}
.about .about-wrapper img {
  border-radius: 1rem;
  max-width: 100%;
  margin: 0 auto;
  height: auto;
}
@media (max-width: 576px) {
  .about .about-wrapper img {
    width: 100%;
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .about .about-wrapper img {
    width: 100%;
  }
}
.about .about-wrapper .about-content h1 {
  font-size: 3.6rem;
  font-weight: 800;
}
@media (max-width: 576px) {
  .about .about-wrapper .about-content h1 {
    font-size: 1.8rem;
    font-weight: 800;
    margin-bottom: 1rem;
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .about .about-wrapper .about-content h1 {
    font-size: 3rem;
    font-weight: 800;
    margin-bottom: 1rem;
  }
}
.about .about-wrapper .about-content .about-paragraphs p {
  font-size: 0.8rem;
  margin-bottom: 1.5rem;
}
.about .about-wrapper .about-content a {
  color: #9e113d;
  text-decoration: underline;
}
:root {
  --header-height: 3rem;
  /* ========= Fonts and typography ========*/
  --biggest-font-size: 2.25rem;
  --h1-font-size: 1.5rem;
  --h2-font-size: 1.25rem;
  --h3-font-size: 1rem;
  --normal-font-size: 0.938rem;
  --small-font-size: 0.813rem;
  --smaller-font-size: 0.75rem;
  /* ========= Font weight ========*/
  --font-medium: 500;
  --font-semi-bold: 600;
  /* ========= Margenes ========*/
  --mb-1: 0.5rem;
  --mb-2: 1rem;
  --mb-3: 1.5rem;
  --mb-4: 2rem;
  --mb-5: 2.5rem;
  --mb-6: 3rem;
  /* ========= z index ========*/
  --z-tooltip: 10;
  --z-fixed: 100;
}

@media screen and (min-width: 768px) {
  :root {
    --biggest-font-size: 4rem;
    --h1-font-size: 2.25rem;
    --h2-font-size: 1.5rem;
    --h3-font-size: 1.25rem;
    --normal-font-size: 1rem;
    --small-font-size: 0.875rem;
    --smaller-font-size: 0.813rem;
  }
}
.bd-container {
  max-width: 960px;
  width: calc(100% - 2rem);
  margin-left: 1rem;
  margin-left: var(--mb-2);
  margin-right: 1rem;
  margin-right: var(--mb-2);
}

.bd-grid {
  display: grid;
  grid-gap: 1.5rem;
  gap: 1.5rem;
}

body {
  margin: 3rem 0 0 0;
  margin: var(--header-height) 0 0 0;
  font-size: 0.938rem;
  font-size: var(--normal-font-size);
  line-height: 1.6;
  scroll-behavior: smooth;
}

html,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

a {
  text-decoration: none;
}

.heading {
  color: #1a202c;
  font-weight: 600;
  width: 100%;
  font-size: 1.5rem;
  position: relative;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding-bottom: 0.5rem;
  margin-bottom: 2.5rem;
}
@media (max-width: 576px) {
  .heading {
    width: 90%;
    font-size: 1.1rem;
    font-weight: 500;
    padding-bottom: 0.3rem;
    margin-bottom: 0;
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .heading {
    font-size: 1.1rem;
    font-weight: 500;
    padding-bottom: 0.3rem;
    margin-bottom: 0;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .heading {
    font-size: 1.1rem;
    font-weight: 500;
  }
}

.public-wrapper {
  display: flex;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}

.container-wrapper {
  width: 120rem;
  margin: 0 auto;
}

.footer {
  display: flex;
  justify-content: center;
  padding-bottom: 1rem;
  font-size: 0.8rem;
}
@media (max-width: 576px) {
  .footer {
    font-size: 0.5rem;
  }
}

:root {
  --header-height: 3rem;
  /* ========= Fonts and typography ========*/
  --biggest-font-size: 2.25rem;
  --h1-font-size: 1.5rem;
  --h2-font-size: 1.25rem;
  --h3-font-size: 1rem;
  --normal-font-size: 0.938rem;
  --small-font-size: 0.813rem;
  --smaller-font-size: 0.75rem;
  /* ========= Font weight ========*/
  --font-medium: 500;
  --font-semi-bold: 600;
  /* ========= Margenes ========*/
  --mb-1: 0.5rem;
  --mb-2: 1rem;
  --mb-3: 1.5rem;
  --mb-4: 2rem;
  --mb-5: 2.5rem;
  --mb-6: 3rem;
  /* ========= z index ========*/
  --z-tooltip: 10;
  --z-fixed: 100;
}

@media screen and (min-width: 768px) {
  :root {
    --biggest-font-size: 4rem;
    --h1-font-size: 2.25rem;
    --h2-font-size: 1.5rem;
    --h3-font-size: 1.25rem;
    --normal-font-size: 1rem;
    --small-font-size: 0.875rem;
    --smaller-font-size: 0.813rem;
  }
}
.bd-container {
  max-width: 960px;
  width: calc(100% - 2rem);
  margin-left: 1rem;
  margin-left: var(--mb-2);
  margin-right: 1rem;
  margin-right: var(--mb-2);
}

.bd-grid {
  display: grid;
  grid-gap: 1.5rem;
  gap: 1.5rem;
}

body {
  margin: 3rem 0 0 0;
  margin: var(--header-height) 0 0 0;
  font-size: 0.938rem;
  font-size: var(--normal-font-size);
  line-height: 1.6;
  scroll-behavior: smooth;
}

html,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

a {
  text-decoration: none;
}

.heading {
  color: #1a202c;
  font-weight: 600;
  width: 100%;
  font-size: 1.5rem;
  position: relative;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding-bottom: 0.5rem;
  margin-bottom: 2.5rem;
}
@media (max-width: 576px) {
  .heading {
    width: 90%;
    font-size: 1.1rem;
    font-weight: 500;
    padding-bottom: 0.3rem;
    margin-bottom: 0;
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .heading {
    font-size: 1.1rem;
    font-weight: 500;
    padding-bottom: 0.3rem;
    margin-bottom: 0;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .heading {
    font-size: 1.1rem;
    font-weight: 500;
  }
}

.public-wrapper {
  display: flex;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}

.container-wrapper {
  width: 120rem;
  margin: 0 auto;
}

.blog {
  max-width: 1024px;
  width: calc(100% - 2rem);
  margin: 4rem auto;
}
@media (max-width: 576px) {
  .blog {
    max-width: 1024px;
    width: 95%;
  }
}
.blog .blog-heading {
  margin-bottom: o auto;
  margin-bottom: 1rem;
  margin-left: 6rem;
}
@media (max-width: 576px) {
  .blog .blog-heading {
    margin-left: 30px;
    font-size: 1rem !important;
    white-space: nowrap;
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .blog .blog-heading {
    margin-left: 30px;
    font-size: 1.3rem !important;
    white-space: nowrap;
  }
}
.blog .blog-container {
  width: 90%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  margin-bottom: 3rem;
}
@media (max-width: 576px) {
  .blog .blog-container {
    grid-template-columns: 1fr;
    margin-top: 2rem;
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .blog .blog-container {
    grid-template-columns: 1fr;
    margin-top: 2rem;
  }
}
.blog .blog-card {
  padding: 2rem;
  transition: 0.5s ease-in-out;
  background: #efe3d8;
  border-radius: 8px;
}
.blog .blog-card:hover {
  -webkit-transform: scale(0.985);
          transform: scale(0.985);
}
.blog .blog-title {
  font-weight: 500;
  font-size: 0.7rem;
  margin-bottom: 0.5rem;
}
@media (max-width: 576px) {
  .blog .blog-title {
    font-size: 0.8rem;
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .blog .blog-title {
    font-size: 0.8rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .blog .blog-title {
    font-size: 0.8rem;
  }
}
.blog .read {
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  color: gray;
}
@media (max-width: 576px) {
  .blog .read {
    font-size: 0.6rem;
  }
}
@media (max-width: 576px) {
  .blog .read {
    font-size: 0.6rem;
  }
}
@media (max-width: 576px) {
  .blog .read {
    font-size: 0.6rem;
  }
}
:root {
  --header-height: 3rem;
  /* ========= Fonts and typography ========*/
  --biggest-font-size: 2.25rem;
  --h1-font-size: 1.5rem;
  --h2-font-size: 1.25rem;
  --h3-font-size: 1rem;
  --normal-font-size: 0.938rem;
  --small-font-size: 0.813rem;
  --smaller-font-size: 0.75rem;
  /* ========= Font weight ========*/
  --font-medium: 500;
  --font-semi-bold: 600;
  /* ========= Margenes ========*/
  --mb-1: 0.5rem;
  --mb-2: 1rem;
  --mb-3: 1.5rem;
  --mb-4: 2rem;
  --mb-5: 2.5rem;
  --mb-6: 3rem;
  /* ========= z index ========*/
  --z-tooltip: 10;
  --z-fixed: 100;
}

@media screen and (min-width: 768px) {
  :root {
    --biggest-font-size: 4rem;
    --h1-font-size: 2.25rem;
    --h2-font-size: 1.5rem;
    --h3-font-size: 1.25rem;
    --normal-font-size: 1rem;
    --small-font-size: 0.875rem;
    --smaller-font-size: 0.813rem;
  }
}
.bd-container {
  max-width: 960px;
  width: calc(100% - 2rem);
  margin-left: 1rem;
  margin-left: var(--mb-2);
  margin-right: 1rem;
  margin-right: var(--mb-2);
}

.bd-grid {
  display: grid;
  grid-gap: 1.5rem;
  gap: 1.5rem;
}

body {
  margin: 3rem 0 0 0;
  margin: var(--header-height) 0 0 0;
  font-size: 0.938rem;
  font-size: var(--normal-font-size);
  line-height: 1.6;
  scroll-behavior: smooth;
}

html,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

a {
  text-decoration: none;
}

.heading {
  color: #1a202c;
  font-weight: 600;
  width: 100%;
  font-size: 1.5rem;
  position: relative;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding-bottom: 0.5rem;
  margin-bottom: 2.5rem;
}
@media (max-width: 576px) {
  .heading {
    width: 90%;
    font-size: 1.1rem;
    font-weight: 500;
    padding-bottom: 0.3rem;
    margin-bottom: 0;
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .heading {
    font-size: 1.1rem;
    font-weight: 500;
    padding-bottom: 0.3rem;
    margin-bottom: 0;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .heading {
    font-size: 1.1rem;
    font-weight: 500;
  }
}

.public-wrapper {
  display: flex;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}

.container-wrapper {
  width: 120rem;
  margin: 0 auto;
}

.courses-heading {
  margin-bottom: o auto;
  margin-bottom: 1rem;
}
@media (max-width: 576px) {
  .courses-heading {
    margin-left: 20px;
    font-size: 1rem !important;
    white-space: nowrap;
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .courses-heading {
    margin-left: 30px;
    font-size: 1.3rem !important;
    white-space: nowrap;
  }
}

img {
  margin-top: -1.3rem;
  width: 100%;
  height: 22rem;
  object-fit: cover;
}
:root {
  --header-height: 3rem;
  /* ========= Fonts and typography ========*/
  --biggest-font-size: 2.25rem;
  --h1-font-size: 1.5rem;
  --h2-font-size: 1.25rem;
  --h3-font-size: 1rem;
  --normal-font-size: 0.938rem;
  --small-font-size: 0.813rem;
  --smaller-font-size: 0.75rem;
  /* ========= Font weight ========*/
  --font-medium: 500;
  --font-semi-bold: 600;
  /* ========= Margenes ========*/
  --mb-1: 0.5rem;
  --mb-2: 1rem;
  --mb-3: 1.5rem;
  --mb-4: 2rem;
  --mb-5: 2.5rem;
  --mb-6: 3rem;
  /* ========= z index ========*/
  --z-tooltip: 10;
  --z-fixed: 100;
}

@media screen and (min-width: 768px) {
  :root {
    --biggest-font-size: 4rem;
    --h1-font-size: 2.25rem;
    --h2-font-size: 1.5rem;
    --h3-font-size: 1.25rem;
    --normal-font-size: 1rem;
    --small-font-size: 0.875rem;
    --smaller-font-size: 0.813rem;
  }
}
.bd-container {
  max-width: 960px;
  width: calc(100% - 2rem);
  margin-left: 1rem;
  margin-left: var(--mb-2);
  margin-right: 1rem;
  margin-right: var(--mb-2);
}

.bd-grid {
  display: grid;
  grid-gap: 1.5rem;
  gap: 1.5rem;
}

body {
  margin: 3rem 0 0 0;
  margin: var(--header-height) 0 0 0;
  font-size: 0.938rem;
  font-size: var(--normal-font-size);
  line-height: 1.6;
  scroll-behavior: smooth;
}

html,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

a {
  text-decoration: none;
}

.heading {
  color: #1a202c;
  font-weight: 600;
  width: 100%;
  font-size: 1.5rem;
  position: relative;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding-bottom: 0.5rem;
  margin-bottom: 2.5rem;
}
@media (max-width: 576px) {
  .heading {
    width: 90%;
    font-size: 1.1rem;
    font-weight: 500;
    padding-bottom: 0.3rem;
    margin-bottom: 0;
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .heading {
    font-size: 1.1rem;
    font-weight: 500;
    padding-bottom: 0.3rem;
    margin-bottom: 0;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .heading {
    font-size: 1.1rem;
    font-weight: 500;
  }
}

.public-wrapper {
  display: flex;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}

.container-wrapper {
  width: 120rem;
  margin: 0 auto;
}

.year-container .name {
  color: #9e113d;
  margin-bottom: 0.1rem;
  font-size: 1rem;
  font-weight: 600;
}
@media (max-width: 576px) {
  .year-container .name {
    margin-bottom: 0rem;
    font-size: 0.9rem;
  }
}
.year-container .issue {
  font-size: 0.9rem;
}
@media (max-width: 576px) {
  .year-container .issue {
    font-size: 0.8rem;
  }
}
.year-container .issue-by {
  font-size: 0.9rem;
  font-weight: 600;
}
@media (max-width: 576px) {
  .year-container .issue-by {
    font-size: 0.8rem;
  }
}
.year-container .date {
  font-style: italic;
}
@media (max-width: 576px) {
  .year-container .date {
    font-size: 0.8rem;
  }
}
.year-container .credential-link {
  text-decoration: underline;
  color: #1a202c;
  margin-left: 0.5rem;
}
@media (max-width: 576px) {
  .year-container .credential-link {
    font-size: 0.8rem;
  }
}
:root {
  --header-height: 3rem;
  /* ========= Fonts and typography ========*/
  --biggest-font-size: 2.25rem;
  --h1-font-size: 1.5rem;
  --h2-font-size: 1.25rem;
  --h3-font-size: 1rem;
  --normal-font-size: 0.938rem;
  --small-font-size: 0.813rem;
  --smaller-font-size: 0.75rem;
  /* ========= Font weight ========*/
  --font-medium: 500;
  --font-semi-bold: 600;
  /* ========= Margenes ========*/
  --mb-1: 0.5rem;
  --mb-2: 1rem;
  --mb-3: 1.5rem;
  --mb-4: 2rem;
  --mb-5: 2.5rem;
  --mb-6: 3rem;
  /* ========= z index ========*/
  --z-tooltip: 10;
  --z-fixed: 100;
}

@media screen and (min-width: 768px) {
  :root {
    --biggest-font-size: 4rem;
    --h1-font-size: 2.25rem;
    --h2-font-size: 1.5rem;
    --h3-font-size: 1.25rem;
    --normal-font-size: 1rem;
    --small-font-size: 0.875rem;
    --smaller-font-size: 0.813rem;
  }
}
.bd-container {
  max-width: 960px;
  width: calc(100% - 2rem);
  margin-left: 1rem;
  margin-left: var(--mb-2);
  margin-right: 1rem;
  margin-right: var(--mb-2);
}

.bd-grid {
  display: grid;
  grid-gap: 1.5rem;
  gap: 1.5rem;
}

body {
  margin: 3rem 0 0 0;
  margin: var(--header-height) 0 0 0;
  font-size: 0.938rem;
  font-size: var(--normal-font-size);
  line-height: 1.6;
  scroll-behavior: smooth;
}

html,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

a {
  text-decoration: none;
}

.heading {
  color: #1a202c;
  font-weight: 600;
  width: 100%;
  font-size: 1.5rem;
  position: relative;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding-bottom: 0.5rem;
  margin-bottom: 2.5rem;
}
@media (max-width: 576px) {
  .heading {
    width: 90%;
    font-size: 1.1rem;
    font-weight: 500;
    padding-bottom: 0.3rem;
    margin-bottom: 0;
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .heading {
    font-size: 1.1rem;
    font-weight: 500;
    padding-bottom: 0.3rem;
    margin-bottom: 0;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .heading {
    font-size: 1.1rem;
    font-weight: 500;
  }
}

.public-wrapper {
  display: flex;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}

.container-wrapper {
  width: 120rem;
  margin: 0 auto;
}

.certifications {
  max-width: 1024px;
  width: calc(100% - 4rem);
  margin: 4rem auto;
}
@media (max-width: 576px) {
  .certifications {
    max-width: 1024px;
    width: 95%;
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .certifications {
    max-width: 1024px;
    width: 95%;
  }
}
:root {
  --header-height: 3rem;
  /* ========= Fonts and typography ========*/
  --biggest-font-size: 2.25rem;
  --h1-font-size: 1.5rem;
  --h2-font-size: 1.25rem;
  --h3-font-size: 1rem;
  --normal-font-size: 0.938rem;
  --small-font-size: 0.813rem;
  --smaller-font-size: 0.75rem;
  /* ========= Font weight ========*/
  --font-medium: 500;
  --font-semi-bold: 600;
  /* ========= Margenes ========*/
  --mb-1: 0.5rem;
  --mb-2: 1rem;
  --mb-3: 1.5rem;
  --mb-4: 2rem;
  --mb-5: 2.5rem;
  --mb-6: 3rem;
  /* ========= z index ========*/
  --z-tooltip: 10;
  --z-fixed: 100;
}

@media screen and (min-width: 768px) {
  :root {
    --biggest-font-size: 4rem;
    --h1-font-size: 2.25rem;
    --h2-font-size: 1.5rem;
    --h3-font-size: 1.25rem;
    --normal-font-size: 1rem;
    --small-font-size: 0.875rem;
    --smaller-font-size: 0.813rem;
  }
}
.bd-container {
  max-width: 960px;
  width: calc(100% - 2rem);
  margin-left: 1rem;
  margin-left: var(--mb-2);
  margin-right: 1rem;
  margin-right: var(--mb-2);
}

.bd-grid {
  display: grid;
  grid-gap: 1.5rem;
  gap: 1.5rem;
}

body {
  margin: 3rem 0 0 0;
  margin: var(--header-height) 0 0 0;
  font-size: 0.938rem;
  font-size: var(--normal-font-size);
  line-height: 1.6;
  scroll-behavior: smooth;
}

html,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

a {
  text-decoration: none;
}

.heading {
  color: #1a202c;
  font-weight: 600;
  width: 100%;
  font-size: 1.5rem;
  position: relative;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding-bottom: 0.5rem;
  margin-bottom: 2.5rem;
}
@media (max-width: 576px) {
  .heading {
    width: 90%;
    font-size: 1.1rem;
    font-weight: 500;
    padding-bottom: 0.3rem;
    margin-bottom: 0;
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .heading {
    font-size: 1.1rem;
    font-weight: 500;
    padding-bottom: 0.3rem;
    margin-bottom: 0;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .heading {
    font-size: 1.1rem;
    font-weight: 500;
  }
}

.public-wrapper {
  display: flex;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}

.container-wrapper {
  width: 120rem;
  margin: 0 auto;
}

.feature {
  max-width: 1024px;
  width: calc(100% - 4rem);
  margin: 4rem auto;
}
@media (max-width: 576px) {
  .feature {
    max-width: 1024px;
    width: 95%;
  }
}
.feature .features-heading {
  margin-bottom: o auto;
  margin-bottom: 1rem;
}
@media (max-width: 576px) {
  .feature .features-heading {
    margin-left: 20px;
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .feature .features-heading {
    margin-left: 30px;
  }
}
.feature .features {
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
  grid-gap: 20px;
  padding-bottom: 3rem;
}
@media (max-width: 576px) {
  .feature .features {
    width: 90%;
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .feature .features {
    width: 90%;
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .feature .features {
    width: 90%;
    grid-template-columns: repeat(auto-fill, minmax(40%, 1fr));
  }
}
@media (min-width: 1025px) and (max-width: 1200px) {
  .feature .features {
    width: 90%;
    grid-template-columns: repeat(auto-fill, minmax(40%, 1fr));
  }
}
.feature .features .features-card {
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 4px;
  transition: 0.5s ease-in-out;
  box-shadow: 0px -19px 100px 6px rgba(0, 0, 0, 0.1);
}
.feature .features .features-card:hover {
  -webkit-transform: scale(1.025);
          transform: scale(1.025);
}
.feature .features .features-card .card-wrapper {
  padding: 20px;
  display: flex;
  flex-direction: column;
}
.feature .features .features-card .features-header {
  margin-bottom: 2rem;
}
.feature .features .features-card .features-header .features-title {
  font-weight: 500;
  font-size: 0.9rem;
  color: #9e113d;
  margin-bottom: 0.1rem;
}
@media (max-width: 576px) {
  .feature .features .features-card .features-header .features-title {
    font-size: 0.7rem;
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .feature .features .features-card .features-header .features-title {
    font-size: 0.8rem;
  }
}
.feature .features .features-card .features-header .features-subtitle {
  color: black;
  font-size: 0.6rem;
  margin-bottom: 0rem;
}
@media (max-width: 576px) {
  .feature .features .features-card .features-header .features-subtitle {
    font-size: 0.6rem;
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .feature .features .features-card .features-header .features-subtitle {
    font-size: 0.7rem;
  }
}
.feature .features .features-card .features-header .features-date {
  color: black;
  font-size: 0.6rem;
  text-transform: uppercase;
  letter-spacing: 1px;
}
@media (max-width: 576px) {
  .feature .features .features-card .features-header .features-date {
    font-size: 0.5rem;
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .feature .features .features-card .features-header .features-date {
    font-size: 0.5rem;
  }
}
.feature .features .features-card img {
  margin-top: -1.3rem;
  max-width: 100%;
  height: 22rem;
}
:root {
  --header-height: 3rem;
  /* ========= Fonts and typography ========*/
  --biggest-font-size: 2.25rem;
  --h1-font-size: 1.5rem;
  --h2-font-size: 1.25rem;
  --h3-font-size: 1rem;
  --normal-font-size: 0.938rem;
  --small-font-size: 0.813rem;
  --smaller-font-size: 0.75rem;
  /* ========= Font weight ========*/
  --font-medium: 500;
  --font-semi-bold: 600;
  /* ========= Margenes ========*/
  --mb-1: 0.5rem;
  --mb-2: 1rem;
  --mb-3: 1.5rem;
  --mb-4: 2rem;
  --mb-5: 2.5rem;
  --mb-6: 3rem;
  /* ========= z index ========*/
  --z-tooltip: 10;
  --z-fixed: 100;
}

@media screen and (min-width: 768px) {
  :root {
    --biggest-font-size: 4rem;
    --h1-font-size: 2.25rem;
    --h2-font-size: 1.5rem;
    --h3-font-size: 1.25rem;
    --normal-font-size: 1rem;
    --small-font-size: 0.875rem;
    --smaller-font-size: 0.813rem;
  }
}
.bd-container {
  max-width: 960px;
  width: calc(100% - 2rem);
  margin-left: 1rem;
  margin-left: var(--mb-2);
  margin-right: 1rem;
  margin-right: var(--mb-2);
}

.bd-grid {
  display: grid;
  grid-gap: 1.5rem;
  gap: 1.5rem;
}

body {
  margin: 3rem 0 0 0;
  margin: var(--header-height) 0 0 0;
  font-size: 0.938rem;
  font-size: var(--normal-font-size);
  line-height: 1.6;
  scroll-behavior: smooth;
}

html,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

a {
  text-decoration: none;
}

.heading {
  color: #1a202c;
  font-weight: 600;
  width: 100%;
  font-size: 1.5rem;
  position: relative;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding-bottom: 0.5rem;
  margin-bottom: 2.5rem;
}
@media (max-width: 576px) {
  .heading {
    width: 90%;
    font-size: 1.1rem;
    font-weight: 500;
    padding-bottom: 0.3rem;
    margin-bottom: 0;
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .heading {
    font-size: 1.1rem;
    font-weight: 500;
    padding-bottom: 0.3rem;
    margin-bottom: 0;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .heading {
    font-size: 1.1rem;
    font-weight: 500;
  }
}

.public-wrapper {
  display: flex;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}

.container-wrapper {
  width: 120rem;
  margin: 0 auto;
}

.home {
  margin: 2rem auto;
  max-width: 1024px;
  align-content: center;
  padding: 5rem 0;
}
@media (max-width: 576px) {
  .home {
    margin-top: 0.7rem;
    width: calc(100% - 1rem);
    padding: 1rem 0;
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .home {
    margin-top: 2rem;
    width: calc(100% - 3rem);
    padding: 1rem 0;
  }
}
.home .hero-container {
  display: grid;
  grid-gap: 3rem;
  gap: 3rem;
  grid-template-columns: 1fr 1fr;
}
@media (max-width: 576px) {
  .home .hero-container {
    grid-template-columns: 1fr;
    grid-gap: 1rem;
    gap: 1rem;
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .home .hero-container {
    grid-template-columns: 1fr;
  }
}
.home .hero-container .hero-content {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 1.5rem 0;
  margin: 0 auto;
}
@media (max-width: 576px) {
  .home .hero-container .hero-content {
    width: 100%;
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .home .hero-container .hero-content {
    width: 100%;
  }
}
.home .hero-container .hero-content .hero-content-inner {
  width: 100%;
  margin: 0 auto;
  color: #323232;
}
.home .hero-container .hero-content .hero-content-inner h1 {
  margin-bottom: 40px;
}
.home .hero-container .hero-content .hero-content-inner h1 .hero-content-line {
  margin: 0;
}
.home .hero-container .hero-content .hero-content-inner h1 .hero-content-line .intro {
  font-weight: 500;
  font-size: 1rem;
}
@media (max-width: 576px) {
  .home .hero-container .hero-content .hero-content-inner h1 .hero-content-line .intro {
    font-weight: 400;
    font-size: 1rem;
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .home .hero-container .hero-content .hero-content-inner h1 .hero-content-line .intro {
    font-weight: 400;
    font-size: 1rem;
  }
}
.home .hero-container .hero-content .hero-content-inner h1 .hero-content-line .intro span {
  position: relative;
}
.home .hero-container .hero-content .hero-content-inner h1 .hero-content-line .intro span:before {
  position: absolute;
  left: -200%;
  top: -150%;
  content: "";
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: #f3c4a0;
  z-index: -1;
}
@media (max-width: 576px) {
  .home .hero-container .hero-content .hero-content-inner h1 .hero-content-line .intro span:before {
    left: -200%;
    top: -210%;
  }
}
.home .hero-container .hero-content .hero-content-inner h1 .hero-content-line .hero-name {
  font-size: 3rem;
  color: #9e113d;
  font-weight: 700;
}
@media (max-width: 576px) {
  .home .hero-container .hero-content .hero-content-inner h1 .hero-content-line .hero-name {
    font-size: 2rem;
    font-weight: 800;
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .home .hero-container .hero-content .hero-content-inner h1 .hero-content-line .hero-name {
    font-size: 2.5rem;
  }
}
.home .hero-container .hero-content .hero-content-inner h1 .hero-content-line .hero-title {
  font-size: 1rem;
  font-weight: 500;
  margin-top: 2rem;
  letter-spacing: 2px;
  line-height: 2rem;
}
@media (max-width: 576px) {
  .home .hero-container .hero-content .hero-content-inner h1 .hero-content-line .hero-title {
    margin-top: -0.6rem;
    font-size: 0.8rem;
    letter-spacing: 1px;
    line-height: 1.6rem;
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .home .hero-container .hero-content .hero-content-inner h1 .hero-content-line .hero-title {
    margin-top: 1rem;
    font-size: 1rem;
    letter-spacing: 1px;
    line-height: 25px;
  }
}
.home .hero-container .hero-content .connect {
  margin-top: -4rem;
  display: flex;
  padding: 20px 0;
  grid-gap: 3rem;
  gap: 3rem;
  font-size: 0.6rem;
  font-weight: 600;
  justify-content: flex-start;
  width: 70%;
}
@media (max-width: 576px) {
  .home .hero-container .hero-content .connect {
    position: relative;
    grid-gap: 1rem;
    gap: 1rem;
    margin-top: -3rem;
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .home .hero-container .hero-content .connect {
    position: relative;
    grid-gap: 1rem;
    gap: 1rem;
    margin-top: -2rem;
  }
}
.home .hero-container .hero-content .connect span {
  cursor: pointer;
}
.home .hero-container .hero-content .connect span a {
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  transition: 0.3s ease-in-out;
}
.home .hero-container .hero-content .connect span a .fa {
  font-size: 1.5rem;
}
@media (max-width: 576px) {
  .home .hero-container .hero-content .connect span a .fa {
    font-size: 1rem;
  }
}
.home .hero-container .hero-content .connect span a:hover {
  -webkit-transform: scale(1.4);
          transform: scale(1.4);
}
.home .hero-container .home-image {
  border-radius: 0.8rem;
  max-width: 100%;
  margin: 0 auto;
  height: auto;
}
:root {
  --header-height: 3rem;
  /* ========= Fonts and typography ========*/
  --biggest-font-size: 2.25rem;
  --h1-font-size: 1.5rem;
  --h2-font-size: 1.25rem;
  --h3-font-size: 1rem;
  --normal-font-size: 0.938rem;
  --small-font-size: 0.813rem;
  --smaller-font-size: 0.75rem;
  /* ========= Font weight ========*/
  --font-medium: 500;
  --font-semi-bold: 600;
  /* ========= Margenes ========*/
  --mb-1: 0.5rem;
  --mb-2: 1rem;
  --mb-3: 1.5rem;
  --mb-4: 2rem;
  --mb-5: 2.5rem;
  --mb-6: 3rem;
  /* ========= z index ========*/
  --z-tooltip: 10;
  --z-fixed: 100;
}

@media screen and (min-width: 768px) {
  :root {
    --biggest-font-size: 4rem;
    --h1-font-size: 2.25rem;
    --h2-font-size: 1.5rem;
    --h3-font-size: 1.25rem;
    --normal-font-size: 1rem;
    --small-font-size: 0.875rem;
    --smaller-font-size: 0.813rem;
  }
}
.bd-container {
  max-width: 960px;
  width: calc(100% - 2rem);
  margin-left: 1rem;
  margin-left: var(--mb-2);
  margin-right: 1rem;
  margin-right: var(--mb-2);
}

.bd-grid {
  display: grid;
  grid-gap: 1.5rem;
  gap: 1.5rem;
}

body {
  margin: 3rem 0 0 0;
  margin: var(--header-height) 0 0 0;
  font-size: 0.938rem;
  font-size: var(--normal-font-size);
  line-height: 1.6;
  scroll-behavior: smooth;
}

html,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

a {
  text-decoration: none;
}

.heading {
  color: #1a202c;
  font-weight: 600;
  width: 100%;
  font-size: 1.5rem;
  position: relative;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding-bottom: 0.5rem;
  margin-bottom: 2.5rem;
}
@media (max-width: 576px) {
  .heading {
    width: 90%;
    font-size: 1.1rem;
    font-weight: 500;
    padding-bottom: 0.3rem;
    margin-bottom: 0;
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .heading {
    font-size: 1.1rem;
    font-weight: 500;
    padding-bottom: 0.3rem;
    margin-bottom: 0;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .heading {
    font-size: 1.1rem;
    font-weight: 500;
  }
}

.public-wrapper {
  display: flex;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}

.container-wrapper {
  width: 120rem;
  margin: 0 auto;
}

.project-container {
  max-width: 1024px;
  width: calc(100% - 4rem);
  margin: 4rem auto;
  display: flex;
  flex-direction: column;
  grid-gap: 2rem;
  gap: 2rem;
}
.project-container .heading {
  margin-bottom: 0rem;
}
@media (max-width: 576px) {
  .project-container {
    max-width: 1024px;
    width: calc(100% - 2rem);
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .project-container {
    max-width: 1024px;
    width: calc(100% - 2rem);
  }
}
.project-container .project-card {
  width: 100%;
  margin: 0 auto;
  padding: 30px 30px;
  display: flex;
  align-items: center;
  background-color: #efe3d8;
  border-radius: 8px;
  transition: 0.5s ease-in-out;
}
@media (max-width: 576px) {
  .project-container .project-card {
    padding: 40px 20px;
    width: 95%;
    flex-direction: column;
    align-items: center;
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .project-container .project-card {
    padding: 40px 20px;
    width: 85%;
    flex-direction: column;
    align-items: center;
  }
}
.project-container .project-card:hover {
  border: 1px solid #9e113d;
  -webkit-transform: scale(1.025);
          transform: scale(1.025);
}
.project-container .project-card .project-content {
  width: 70%;
}
@media (max-width: 576px) {
  .project-container .project-card .project-content {
    width: 100%;
    margin: 0 auto;
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .project-container .project-card .project-content {
    width: 100%;
    margin: 0 auto;
  }
}
.project-container .project-card .project-content .project-title {
  color: #1a202c;
  font-weight: 600;
  margin-bottom: 1rem;
}
@media (max-width: 576px) {
  .project-container .project-card .project-content .project-title {
    font-size: 1.5rem;
  }
}
.project-container .project-card .project-content .project-info {
  font-size: 0.8rem;
  color: #4a5568;
  margin-bottom: 2rem;
  width: 90%;
  letter-spacing: 1px;
  font-weight: 100;
}
@media (max-width: 576px) {
  .project-container .project-card .project-content .project-info {
    font-size: 0.7rem;
    width: 100%;
    margin: 30px auto;
    margin-bottom: 1rem;
    margin-top: -0.5rem;
  }
}
.project-container .project-card .project-content .project-technology {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
  color: #1a202c;
  font-weight: 500;
  margin-bottom: 2rem;
  font-size: 0.6rem;
  text-transform: uppercase;
}
.project-container .project-card .project-content .project-technology span {
  letter-spacing: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 576px) {
  .project-container .project-card .project-content .project-technology span {
    letter-spacing: 0;
    padding: 0.1rem;
  }
}
@media (max-width: 576px) {
  .project-container .project-card .project-content .project-technology {
    font-size: 0.5rem;
    justify-content: space-around;
    margin-bottom: 0;
  }
}
.project-container .project-card .project-content .links {
  display: flex;
  grid-gap: 2rem;
  gap: 2rem;
  font-size: 1rem;
}
.project-container .project-card .project-content .links a {
  color: #4a5568;
}
.project-container .project-card .project-content .links .project-icon {
  cursor: pointer;
}
.project-container .project-card .portfolio-img {
  width: 40%;
  height: 15rem;
  margin-top: 0.6rem;
}
@media (max-width: 576px) {
  .project-container .project-card .portfolio-img {
    width: 200px;
    height: 200px;
    margin-top: 1rem;
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .project-container .project-card .portfolio-img {
    width: 200px;
    height: 100%;
    margin-top: 1rem;
  }
}
:root {
  --header-height: 3rem;
  /* ========= Fonts and typography ========*/
  --biggest-font-size: 2.25rem;
  --h1-font-size: 1.5rem;
  --h2-font-size: 1.25rem;
  --h3-font-size: 1rem;
  --normal-font-size: 0.938rem;
  --small-font-size: 0.813rem;
  --smaller-font-size: 0.75rem;
  /* ========= Font weight ========*/
  --font-medium: 500;
  --font-semi-bold: 600;
  /* ========= Margenes ========*/
  --mb-1: 0.5rem;
  --mb-2: 1rem;
  --mb-3: 1.5rem;
  --mb-4: 2rem;
  --mb-5: 2.5rem;
  --mb-6: 3rem;
  /* ========= z index ========*/
  --z-tooltip: 10;
  --z-fixed: 100;
}

@media screen and (min-width: 768px) {
  :root {
    --biggest-font-size: 4rem;
    --h1-font-size: 2.25rem;
    --h2-font-size: 1.5rem;
    --h3-font-size: 1.25rem;
    --normal-font-size: 1rem;
    --small-font-size: 0.875rem;
    --smaller-font-size: 0.813rem;
  }
}
.bd-container {
  max-width: 960px;
  width: calc(100% - 2rem);
  margin-left: 1rem;
  margin-left: var(--mb-2);
  margin-right: 1rem;
  margin-right: var(--mb-2);
}

.bd-grid {
  display: grid;
  grid-gap: 1.5rem;
  gap: 1.5rem;
}

body {
  margin: 3rem 0 0 0;
  margin: var(--header-height) 0 0 0;
  font-size: 0.938rem;
  font-size: var(--normal-font-size);
  line-height: 1.6;
  scroll-behavior: smooth;
}

html,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

a {
  text-decoration: none;
}

.heading {
  color: #1a202c;
  font-weight: 600;
  width: 100%;
  font-size: 1.5rem;
  position: relative;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding-bottom: 0.5rem;
  margin-bottom: 2.5rem;
}
@media (max-width: 576px) {
  .heading {
    width: 90%;
    font-size: 1.1rem;
    font-weight: 500;
    padding-bottom: 0.3rem;
    margin-bottom: 0;
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .heading {
    font-size: 1.1rem;
    font-weight: 500;
    padding-bottom: 0.3rem;
    margin-bottom: 0;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .heading {
    font-size: 1.1rem;
    font-weight: 500;
  }
}

.public-wrapper {
  display: flex;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}

.container-wrapper {
  width: 120rem;
  margin: 0 auto;
}

.features-header.talks {
  margin-bottom: 0 !important;
}

.talk {
  padding: 0.6rem 0 0.6rem;
}

img {
  width: 100%;
  margin-bottom: 1rem;
}
:root {
  --header-height: 3rem;
  /* ========= Fonts and typography ========*/
  --biggest-font-size: 2.25rem;
  --h1-font-size: 1.5rem;
  --h2-font-size: 1.25rem;
  --h3-font-size: 1rem;
  --normal-font-size: 0.938rem;
  --small-font-size: 0.813rem;
  --smaller-font-size: 0.75rem;
  /* ========= Font weight ========*/
  --font-medium: 500;
  --font-semi-bold: 600;
  /* ========= Margenes ========*/
  --mb-1: 0.5rem;
  --mb-2: 1rem;
  --mb-3: 1.5rem;
  --mb-4: 2rem;
  --mb-5: 2.5rem;
  --mb-6: 3rem;
  /* ========= z index ========*/
  --z-tooltip: 10;
  --z-fixed: 100;
}

@media screen and (min-width: 768px) {
  :root {
    --biggest-font-size: 4rem;
    --h1-font-size: 2.25rem;
    --h2-font-size: 1.5rem;
    --h3-font-size: 1.25rem;
    --normal-font-size: 1rem;
    --small-font-size: 0.875rem;
    --smaller-font-size: 0.813rem;
  }
}
.bd-container {
  max-width: 960px;
  width: calc(100% - 2rem);
  margin-left: 1rem;
  margin-left: var(--mb-2);
  margin-right: 1rem;
  margin-right: var(--mb-2);
}

.bd-grid {
  display: grid;
  grid-gap: 1.5rem;
  gap: 1.5rem;
}

body {
  margin: 3rem 0 0 0;
  margin: var(--header-height) 0 0 0;
  font-size: 0.938rem;
  font-size: var(--normal-font-size);
  line-height: 1.6;
  scroll-behavior: smooth;
}

html,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

a {
  text-decoration: none;
}

.heading {
  color: #1a202c;
  font-weight: 600;
  width: 100%;
  font-size: 1.5rem;
  position: relative;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding-bottom: 0.5rem;
  margin-bottom: 2.5rem;
}
@media (max-width: 576px) {
  .heading {
    width: 90%;
    font-size: 1.1rem;
    font-weight: 500;
    padding-bottom: 0.3rem;
    margin-bottom: 0;
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .heading {
    font-size: 1.1rem;
    font-weight: 500;
    padding-bottom: 0.3rem;
    margin-bottom: 0;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .heading {
    font-size: 1.1rem;
    font-weight: 500;
  }
}

.public-wrapper {
  display: flex;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}

.container-wrapper {
  width: 120rem;
  margin: 0 auto;
}

:root {
  --blue: #006eff;
}

.techie {
  max-width: 1024px;
  margin: 0 auto;
}
@media (max-width: 576px) {
  .techie {
    width: 100%;
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .techie {
    width: 100%;
  }
}
.techie .intro-section {
  margin-top: 4rem !important;
  max-width: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 2rem 0 0;
  grid-gap: 2rem;
  gap: 2rem;
  align-content: center;
}
@media (max-width: 576px) {
  .techie .intro-section {
    grid-template-columns: 1fr;
    max-width: 100%;
    box-shadow: none;
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .techie .intro-section {
    grid-template-columns: 1fr;
    flex-direction: column;
    width: 100%;
    box-shadow: none;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .techie .intro-section {
    width: 90%;
    box-shadow: none;
  }
}
@media (min-width: 1025px) and (max-width: 1200px) {
  .techie .intro-section {
    width: 100%;
    box-shadow: none;
  }
}
@media (min-width: 1201px) and (max-width: 1669px) {
  .techie .intro-section {
    width: 100%;
  }
}
.techie .intro-section img {
  width: 100%;
  height: auto;
}
@media (max-width: 576px) {
  .techie .intro-section img {
    max-width: 100%;
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .techie .intro-section img {
    max-width: 100%;
  }
}
.techie .intro-section .book-intro-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: -6rem;
  margin-left: -1.5rem;
}
@media (max-width: 576px) {
  .techie .intro-section .book-intro-content {
    margin: 0 auto;
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .techie .intro-section .book-intro-content {
    margin: 0 auto;
  }
}
.techie .intro-section .book-intro-content .book-intro-title {
  font-size: 2rem;
  width: 100%;
  font-weight: 900;
  text-transform: uppercase;
  line-height: 2.5rem;
  margin-bottom: 2rem;
}
@media (max-width: 576px) {
  .techie .intro-section .book-intro-content .book-intro-title {
    font-size: 2rem;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 2rem;
    text-align: center;
    line-height: 2.5rem;
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .techie .intro-section .book-intro-content .book-intro-title {
    font-size: 2.5rem;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 2rem;
    line-height: 3.2 rem;
    text-align: center;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .techie .intro-section .book-intro-content .book-intro-title {
    width: 100%;
    font-size: 2.5rem;
    line-height: 2.5rem;
  }
}
@media (min-width: 1025px) and (max-width: 1200px) {
  .techie .intro-section .book-intro-content .book-intro-title {
    width: 100%;
    font-size: 2rem;
    line-height: 2.8rem;
  }
}
@media (min-width: 1201px) and (max-width: 1669px) {
  .techie .intro-section .book-intro-content .book-intro-title {
    width: 70%;
  }
}
.techie .intro-section .book-intro-content .book-author {
  font-size: 1.2rem;
  color: #9e113d;
}
@media (max-width: 576px) {
  .techie .intro-section .book-intro-content .book-author {
    font-size: 1rem;
    text-align: center;
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .techie .intro-section .book-intro-content .book-author {
    font-size: 1rem;
    text-align: center;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .techie .intro-section .book-intro-content .book-author {
    font-size: 0.9rem;
    text-align: center;
  }
}
@media (min-width: 1025px) and (max-width: 1200px) {
  .techie .intro-section .book-intro-content .book-author {
    font-size: 0.9rem;
    text-align: center;
  }
}
.techie .available {
  text-align: center;
}
.techie .available h4 {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 3rem;
}
.techie .available div.brand {
  text-align: center;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 0rem;
  grid-gap: 4rem;
  gap: 4rem;
}
.techie .available div.brand img.selar {
  width: 56px;
  height: 30px;
}
.techie .available div.brand img:last-child {
  width: 100px;
  height: 30px;
}
.techie .available .btn {
  margin-top: 1rem;
  margin-bottom: 2rem;
}
.techie .available a {
  background: #006eff;
  background: var(--blue);
  color: white;
  border: none;
  padding: 1rem;
  font-size: 1.1rem;
  border-radius: 8px;
  transition: 0.3s all ease-in-out;
}
.techie .available a:first-child {
  margin-right: 1rem;
}
.techie .available a:hover {
  color: #006eff;
  color: var(--blue);
  background: white;
  border: 2px solid #006eff;
  border: 2px solid var(--blue);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.techie .paragraph {
  width: 100%;
  font-size: 1.2rem;
  margin: 0 auto;
  margin-bottom: 5rem;
  text-align: center;
}
@media (max-width: 576px) {
  .techie .paragraph {
    width: 85%;
    text-align: center;
    font-size: 1rem;
  }
}
.techie .paragraph p {
  margin-bottom: 1rem;
  line-height: 1.8rem;
  font-family: "Karla", sans-serif;
}
.techie .paragraph p span {
  font-style: italic;
}
.techie .head {
  margin: 0 auto;
  width: 70%;
  margin-bottom: 3rem;
}
@media (max-width: 576px) {
  .techie .head {
    width: 85%;
    text-align: center;
    font-size: 0.9rem;
  }
}
.techie .head h1 {
  color: #006eff;
  color: var(--blue);
  font-size: 1.3rem;
  font-weight: 700;
  text-align: center;
}
.techie .head p {
  margin-top: 4rem;
  font-size: 1.2rem;
  font-family: "Karla", sans-serif;
}
.techie .head p:last-child {
  font-style: italic;
  text-align: center;
}
@media (max-width: 576px) {
  .techie .head p:last-child {
    font-size: 1rem;
  }
}
.techie .head p:last-child a {
  color: #006eff;
  color: var(--blue);
  text-decoration: underline;
}
.techie .convinced {
  text-align: center;
  margin-bottom: 3rem;
}
.techie .convinced h2 {
  margin-top: 2rem;
}
@media (max-width: 576px) {
  .techie .convinced h2 {
    font-size: 1rem;
  }
}
.techie .convinced a {
  background: black;
  color: white;
  border: none;
  padding: 1rem;
  font-size: 1.1rem;
  border-radius: 8px;
  transition: 0.3s all ease-in-out;
}
.techie .convinced a:hover {
  color: black;
  background: white;
  border: 2px solid black;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.techie .inside {
  border-top: 1px solid rgb(151, 145, 145);
  margin-top: 7rem;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 5rem;
}
@media (max-width: 576px) {
  .techie .inside {
    width: 95%;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .techie .inside {
    width: 100%;
  }
}
.techie .inside h2 {
  text-align: center;
  margin-bottom: 2rem;
  margin-top: 3rem;
  color: #006eff;
  color: var(--blue);
  text-transform: uppercase;
}
.techie .inside .inside-wrapper {
  display: grid;
  margin: 0 auto;
}
@media (min-width: 489px) {
  .techie .inside .inside-wrapper {
    width: 90%;
    grid-template-columns: repeat(1, 1fr);
  }
}
@media (min-width: 760px) {
  .techie .inside .inside-wrapper {
    min-width: 90%;
    margin: 0 auto;
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 1200px) {
  .techie .inside .inside-wrapper {
    min-width: 100%;
    margin: 0 auto;
    grid-template-columns: repeat(3, 1fr);
  }
}
.techie .inside .inside-wrapper .inside-card {
  padding: 2rem;
  border-radius: 8px;
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
  margin-bottom: 2rem;
  box-shadow: 0px -19px 100px 6px rgba(0, 0, 0, 0.1);
}
.techie .inside .inside-wrapper .inside-card .number {
  height: 60px;
  width: 60px;
  font-weight: 600;
  color: white;
  border-radius: 50%;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #006eff;
  background: var(--blue);
}
.techie .inside .inside-wrapper .inside-card .inside-content {
  width: 70%;
}
.techie .inside .inside-wrapper .inside-card .inside-content h1 {
  font-size: 1.2rem;
  font-weight: 700;
  font-family: "Karla", sans-serif;
}
.techie .inside .inside-wrapper .inside-card .inside-content p {
  color: gray;
  font-size: 0.9rem;
  font-family: "Karla", sans-serif;
}
.techie .inside .inside-wrapper .inside-wrapper {
  grid-column-end: 5;
}
.techie .contributors {
  width: 100%;
  border-top: 1px solid rgb(151, 145, 145);
  padding-top: 2rem;
  margin: 0 auto;
  margin-bottom: 4rem;
}
@media (max-width: 576px) {
  .techie .contributors {
    width: 90%;
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .techie .contributors {
    width: 95%;
  }
}
.techie .contributors h1 {
  text-align: center;
  color: #006eff;
  color: var(--blue);
  font-weight: 800;
}
.techie .contributors p {
  margin-bottom: 4rem;
  text-align: center;
}
.techie .contributors .contributor-wrapper {
  width: 100%;
  margin: 0 auto;
  display: grid;
  grid-gap: 1rem;
}
@media (min-width: 489px) {
  .techie .contributors .contributor-wrapper {
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 960px) {
  .techie .contributors .contributor-wrapper {
    min-width: 100%;
    margin: 0 auto;
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 1200px) {
  .techie .contributors .contributor-wrapper {
    min-width: 100%;
    margin: 0 auto;
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 1550px) {
  .techie .contributors .contributor-wrapper {
    min-width: 100%;
    margin: 0 auto;
    grid-template-columns: repeat(3, 1fr);
  }
}
.techie .contributors .contributor-wrapper .contributor-card {
  box-shadow: 0px -19px 100px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
  padding: 0.2rem;
  border-radius: 20px;
}
@media (max-width: 576px) {
  .techie .contributors .contributor-wrapper .contributor-card {
    padding: 0.1rem;
  }
}
.techie .contributors .contributor-wrapper .contributor-card img {
  display: flex;
  margin: 0 auto;
  width: 100%;
  height: 24rem;
  border-radius: 1rem;
}
.techie .contributors .contributor-wrapper .contributor-card .co-about {
  text-align: center;
}
.techie .contributors .contributor-wrapper .contributor-card .co-about p:first-child {
  margin-top: 1.5rem;
  font-size: 1rem;
  font-weight: 800;
  margin-bottom: 0.5rem;
  letter-spacing: 1px;
  font-family: "Karla", sans-serif;
}
.techie .contributors .contributor-wrapper .contributor-card .co-about p.role {
  margin-bottom: 0.6rem;
  font-family: "Karla", sans-serif;
  font-weight: 500;
}
.techie .contributors .contributor-wrapper .contributor-card .co-about .connect {
  display: flex;
  justify-content: center;
  color: #006eff;
  font-size: 1.5rem;
  grid-gap: 1rem;
  gap: 1rem;
}
.techie .book-mockup {
  border-top: 1px solid rgb(151, 145, 145);
  padding-top: 3rem;
  width: 80%;
  margin: 0 auto;
  text-align: center;
}
@media (max-width: 576px) {
  .techie .book-mockup {
    width: 90%;
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .techie .book-mockup {
    width: 90%;
  }
}
.techie .book-mockup h1 {
  color: #006eff;
  color: var(--blue);
  text-align: center;
  margin-bottom: 3rem;
  font-weight: 800;
}
.techie .book-mockup .book-mockup-wrapper {
  width: 90%;
  margin: 0 auto;
  grid-gap: 2rem;
  gap: 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 2.5rem;
}
@media (max-width: 576px) {
  .techie .book-mockup .book-mockup-wrapper {
    width: 100%;
    grid-template-columns: 1fr;
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .techie .book-mockup .book-mockup-wrapper {
    width: 80%;
    grid-template-columns: 1fr;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .techie .book-mockup .book-mockup-wrapper {
    width: 80%;
    grid-template-columns: 1fr;
  }
}
@media (min-width: 1025px) and (max-width: 1200px) {
  .techie .book-mockup .book-mockup-wrapper {
    width: 80%;
    grid-template-columns: 1fr;
  }
}
.techie .book-mockup .book-mockup-wrapper img {
  width: 100%;
  border-radius: 1rem;
}
.techie .book-mockup a {
  background: #006eff;
  background: var(--blue);
  color: white;
  border: none;
  padding: 1rem;
  font-size: 1.1rem;
  border-radius: 8px;
  transition: 0.3s all ease-in-out;
  margin-right: 1rem;
}
.techie .book-mockup a:hover {
  color: #006eff;
  color: var(--blue);
  background: white;
  border: 2px solid #006eff;
  border: 2px solid var(--blue);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.techie .author {
  border-top: 1px solid rgb(151, 145, 145);
  padding-top: 4rem;
  width: 95%;
  margin: 0 auto;
  margin-bottom: 5rem !important;
  margin-top: 10rem;
}
.techie .author h1 {
  text-align: center;
  color: #006eff;
  color: var(--blue);
  font-weight: 800;
  margin-bottom: 3rem;
}
.techie .author .wrapper {
  display: grid;
  grid-template-columns: 1fr 2fr;
  justify-content: center;
  grid-gap: 2rem;
  gap: 2rem;
  align-items: center;
}
@media (max-width: 576px) {
  .techie .author .wrapper {
    width: 100%;
    grid-template-columns: 1fr;
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .techie .author .wrapper {
    width: 100%;
    grid-template-columns: 1fr;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .techie .author .wrapper {
    width: 100%;
    grid-template-columns: 1fr;
  }
}
@media (min-width: 1025px) and (max-width: 1200px) {
  .techie .author .wrapper {
    width: 100%;
    grid-template-columns: 1fr;
  }
}
.techie .author .wrapper img {
  max-width: 100%;
  height: 90%;
  margin-top: -2rem;
  border-radius: 1rem;
}
.techie .author .wrapper .content p {
  font-family: "Karla", sans-serif;
  font-size: 1.12rem;
}
@media (max-width: 576px) {
  .techie .author .wrapper .content p {
    width: 90%;
    margin: 0 auto;
    font-size: 0.9rem;
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .techie .author .wrapper .content p {
    font-size: 0.9rem;
    width: 100%;
    margin: 0 auto;
  }
}
.techie .author .wrapper .content p.quote {
  font-style: italic;
  font-weight: 800;
  color: rgb(216, 203, 203);
}
.techie .pre-order {
  align-items: center;
}
