@import "../../App.scss";

.certifications {
  max-width: 1024px;
  width: calc(100% - 4rem);
  margin: 4rem auto;

  @include respond(mobile) {
    max-width: 1024px;
    width: 95%;
  }

  @include respond(tablet) {
    max-width: 1024px;
    width: 95%;
  }
}
